import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import MuxPlayer from "@mux/mux-player-react";

const Hero = () => {
  return (
    <section className="w-full min-h-screen bg-white flex items-center justify-center px-8">
      <div className="w-full max-w-7xl relative">
        {/* Main Content Container */}
        <div className="desktop:flex laptop:flex tablet:block mobile:block justify-between items-start">
          {/* Left Column */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full"
          >
            <h1 className="desktop:text-8xl laptop:text-7xl tablet:text-6xl mobile:text-5xl font-bold text-black tracking-tight leading-none mb-8">
              Test
            </h1>
            <p className="desktop:text-xl laptop:text-xl tablet:text-lg mobile:text-base text-gray-600 desktop:w-4/5 laptop:w-4/5 tablet:w-full mobile:w-full mb-12">
              Minimalist black and white design, sharp typography, no images,
              lots of whitespace.
            </p>

            {/* CTA Buttons */}
            <div className="desktop:flex laptop:flex tablet:flex mobile:block space-x-0 desktop:space-x-6 laptop:space-x-6 tablet:space-x-6">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="w-full tablet:w-auto mobile:w-full bg-black text-white px-8 py-4 rounded-none font-medium desktop:mb-0 laptop:mb-0 tablet:mb-0 mobile:mb-4"
              >
                Get Started
                <ArrowRight className="inline ml-2" />
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="w-full tablet:w-auto mobile:w-full border border-black text-black px-8 py-4 rounded-none font-medium"
              >
                Learn More
              </motion.button>
            </div>
          </motion.div>

          {/* Right Column */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full desktop:mt-0 laptop:mt-0 tablet:mt-16 mobile:mt-16"
          >
            <div className="relative">
              <p className="text-sm text-gray-500 mb-6">Featured Image</p>
              <div className="space-y-4">
                <MuxPlayer
                  src="https://stream.mux.com/Bee9L5EuHbjWO5UqBZQCa01CDp85mIqJMSolcELwLdQA.m3u8"
                  playbackId="Bee9L5EuHbjWO5UqBZQCa01CDp85mIqJMSolcELwLdQA"
                  className="w-full aspect-video"
                  metadata={{ video_title: "Featured Video" }}
                />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

// Arrow Right Icon Component
const ArrowRight = ({ className = "" }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8H15M15 8L8 1M15 8L8 15"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Hero;
